import React from "react";
//import { makeStyles, Theme } from "@material-ui/core/styles";

import firebase from "firebase/app";

import { firebaseConfig } from "../../config/firebase.config";
import Claustrofonie from "../claustrofonie/Claustrofonie";

const App: React.FC = () => {
  // const useStyles = makeStyles((theme: Theme) => ({
  //   offset: {
  //     maxWidth: "none",
  //     padding: "6rem 2rem",
  //     [theme.breakpoints.down("sm")]: {
  //       padding: "5rem 1rem"
  //     }
  //   }
  // }));
  firebase.initializeApp(firebaseConfig);
  return (
    <Claustrofonie />
  );
};

export default App;
