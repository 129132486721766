import React from 'react';
import './Claustrofonie.css';
import Logo from '../../images/logo.png';
import FacebookIcon from '../../images/facebook-icoon.png';
import YouTubeIcon from '../../images/youtube-icoon.png';

export default () => (
    <div className="page">
        <header className="header">
            <img alt='logo' className="logo" src={Logo} />
        </header>
        <div>
            <h1 className="title">Video</h1>


            <div className="videoWrapper">
                <iframe
                    title="youtube video"
                    className="video"
                    src={'https://www.youtube.com/embed/VsF7j2kEivc'}
                >

                </iframe>
            </div>
        </div>
        <div>
            <h1 className="title">Contact</h1>
            <p>
                info@claustrofonie.nl
	</p>
            <h1 className="title">Sociaal</h1>
            <div className="social">
                <a
                    href="https://facebook.com/claustrofonie"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social__link">
                    <img src={FacebookIcon} alt="facebook" />
                </a>
                <a href="https://youtube.com/claustrofonie"

                    target="_blank"
                    rel="noopener noreferrer"
                    className="social__link">
                    <img src={YouTubeIcon} alt='youtube' />
                </a>

            </div>


        </div>
    </div>

)